import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <div className="container pt-24 md:pt-36">
          <h1 className="text-5xl font-extralight uppercase">Page not found</h1>
          <p className="font-extralight text-lg">
            Sorry 😔, we couldn’t find what you were looking for.{" "}
            <Link to="/">Go back home</Link>.
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => (
  <>
    <title>
      JEWEL Residences @ Serpong, Tangerang Selatan - 404 Page Not Found
    </title>
    <meta
      name="description"
      content="New upcoming exclusive cluster project coming to you. Strategically located at Serpong, Tangerang Selatan, Jabodetabek, Indonesia. Get your dream home now! Contact Us for more information."
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;700;800&display=swap"
      rel="stylesheet"
    ></link>
    <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-K3DM7CP"
    ></script>
  </>
);
